import { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authInstance } from '../../../modules/api/api';

export default function CallbackFailure(props){
  const navigate = useNavigate();
  const userData = props.userData;

  useEffect(() => {
    alert('결제에 실패했습니다. 잠시 후 다시 사용해주세요.')
    navigate('/payment')
  }, []);  

  return (
      <>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
              결제 오류가 발생했습니다.
          </div>
      </>
    )
}
