import { useEffect, useRef, useState } from 'react';
import './../../../App.css';
import { MultiPassPage } from '../../../components/Payment/MultiPassPage';
import { baseInstance, authInstance } from '../../../modules/api/api';
import Header from '../../Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckoutPage } from '../../../components/TossPayment/Checkout';

import { Buffer } from 'buffer';

export default function PaymentNext(props){
    const userData = props.userData;
    const navigate = useNavigate();

    const location = useLocation();
    const itemPrice = location.state.itemPrice;
    const itemNumber = location.state?.itemNumber;
    const itemName = location.state.itemName
    localStorage.setItem('price', itemPrice);
    localStorage.setItem('number_of_churr', itemNumber)
    localStorage.setItem('itemName', itemName);

    return(
        <>
            <Header userData={userData} />
            <div className='payment'>
                <div className='payment__con'>

                    <div className='payment-next__info-con'>
                        <div>
                            <div>{itemPrice?.toLocaleString()}원</div>
                            <div>{itemName == '12시간 무제한 이용권' ? itemName : ''} {itemNumber != 920806 ? itemNumber : 1}개</div>
                        </div>
                        <span>
                            <span>‼주의 :</span> 결제 진행 중 또는 결제 완료 후에 <br className='br-mo'/><span>‘새로고침’ 및 ‘뒤로가기’ 버튼을 누르지 마세요.</span> <br/>본인의 실수로 인한 경우 <span>환불되지 않습니다‼</span>
                        </span>
                    </div>                    
                    <CheckoutPage userData={userData} />
                </div>
            </div>
        </>
    )
}