import { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import inputData from '../../../modules/inputData/inputData';
import { authInstance, baseInstance } from '../../../modules/api/api';

export default function CallbackCoupon(){
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = localStorage.getItem('pathname');
  let code = location.state?.code;


  const getAPI = async () => {
    try {

      const resp1 = await baseInstance.post(`payment/coupon?user_id=${localStorage.getItem('user_id')}&code=${code}`)

      if (pathname === '/chat/room') {

        const resp2 = await authInstance.get(`chat/v3/room/${localStorage.getItem("room_id")}?q_type=${localStorage.getItem("category")}`, {
          room_id : localStorage.getItem('room_id')
        });
        const usingChurr = await authInstance.patch(`churr?q_type=${localStorage.getItem("category")}&amount=${2}`)

        alert('쿠폰이 적용되었습니다. 타로결과 페이지로 이동합니다.')
        navigate(localStorage.getItem('pathname'), {state : {
          data : resp2.data.chat[0].data,
          cardIndex : resp2.data.chat[1].cardIndex,
          roomId : localStorage.getItem('room_id')
          }});
        window.location.reload();
      }

      else if (pathname === '/user/info') {
        alert('쿠폰이 적용되었습니다. 유저정보 페이지로 이동합니다.');
        navigate(localStorage.getItem('pathname'));
        window.location.reload();
      }
      
      else {
        alert('일시적인 오류가 발생했습니다. 문의주시면 빠르게 해결해드리겠습니다.');
        navigate(localStorage.getItem('pathname'));
        window.location.reload()
      }

    } catch (err) {
        if (err.response.status == 403) {
          alert('이미 쿠폰을 사용한 내역이 있습니다.')
          navigate('/payment')
        }
        console.log('API Error >>', err)
    }
  }
  useEffect(() => {
      getAPI();
  }, [])



  // let [dataObj, setDataObj] = useState({});
  // const [data, setData] = useState(null);

  // // coupon event
  // const postAPI = async () => {
  //   try {
  //       const resp = await baseInstance.post(`payment/coupon?user_id=${localStorage.getItem('user_id')}`)
  //       console.log(resp);
  //       setData(resp.data);
  //       localStorage.setItem('tid', resp.data.tid)

  //       alert('쿠폰이 적용되었습니다. 타로결과 페이지로 이동합니다.')
  //       navigate(localStorage.getItem('pathname'), {state : {
  //         data : resp.data.chat_data.data,
  //         cardIndex : resp.data.chat_data.cardIndex
  //       }});
  //   } catch (err) {
  //     if (err.response.status == 403) {
  //       alert('이미 쿠폰을 사용한 내역이 있습니다.')
  //       navigate('/payment')
  //     }
  //     console.log('API Error >>', err)
  //   }
  // }
  // useEffect(() => {
  //   postAPI();
  // }, []);


  return (
      <>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
              잠시만 기다려주세요.<br/>
              타로결과 페이지로 이동합니다.
          </div>
      </>
    )
}
