import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Intro, Footer, Header,
        Category, Room,
        CategoryNew,
        Payment, PaymentNext,
        Signin, CallbackNaver,
        CallbackFailure,
        TossCallback,
        CallbackUsingChurr,
        CallbackCoupon,
        UserInfo,
        License
        } from './routes';
import { authInstance } from './modules/api/api';
import inputData from './modules/inputData/inputData';


function App() {

  // get user
  let [dataObj, setDataObj] = useState({});
  const [userData, setUserData] = useState(null);
  const { pathname } = useLocation();
  console.log('app.js path >>>>>', pathname)
  const getAPI = async () => {
      try {
          const resp = await authInstance.get(`/user/${localStorage.getItem('user_id')}`)
          setUserData(resp.data)

          inputData(setDataObj, 'name', resp.data.name);
          localStorage.setItem('nickname', resp.data.name);

          // console.log(resp.data)
      } catch (err) {
          console.log('Error >>', err)
          
          if (err.response && err.response.status == 401 && err.response.data.detail == 'Token expired.') {
            console.log('token expire>>>>')
          }
      }
  }
  useEffect(() => {
      if (localStorage.getItem('user_token')){
          getAPI()
      }
  }, [])

  return (
    
    <div className="App">
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Intro />} />

        <Route path="/chat/category" element={<Category userData={userData} />} />
        <Route path="/chat/category/new" element={<CategoryNew userData={userData} />} />
        <Route path="/chat/room" element={<Room userData={userData} />} />

        <Route path="/payment">
          <Route path="" element={<Payment userData={userData} />} />
          <Route path="next" element={<PaymentNext userData={userData} />} />
          <Route path="failure" element={<CallbackFailure userData={userData} />} />
          <Route path="coupon" element={<CallbackCoupon userData={userData} />} />
          <Route path="usingchurr" element={<CallbackUsingChurr userData={userData} />} />
        </Route>        
        

        {/* toss */}
        <Route path="/toss/serverAuth" element={<TossCallback userData={userData} />} />

        
        <Route path="/user">
          <Route path="signin" element={ <Signin />} />
          <Route path="auth/callback/naver" element={ <CallbackNaver />} />
          <Route path="info" element={ <UserInfo userData={userData} />} />
        </Route>

        <Route path="/license" element={<License />} />
      </Routes>
        {/* <Footer /> */}
    </div>
  );
}

export default App;
