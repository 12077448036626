import { useEffect, useRef, useState } from 'react';
import './../../../App.css';
import NaverLogin from '../../../components/login/NaverLogin';
import Header from '../../Header';
import { useNavigate } from 'react-router-dom';


export default function Signin(){
    const navigate = useNavigate();

    const [isClicked, setIsClicked] = useState(false);
    const askLogin = () => {
        setIsClicked(!isClicked);
    }

    return(
        <>
            <Header />
            <div className='signin'>
                <div className='signin__con'>
                    <div className='signin__header'>
                        로그인
                    </div>
                    <ul className='signin__social-con'>
                        <NaverLogin />
                        {/* <li className='signin__social-qna' onClick={()=>{navigate("/chat/room")}}>
                            비회원 시작하기
                        </li> */}
                        {/* <li className='signin__social-qna' onClick={() => {alert('준비중입니다')}}>
                            로그인 문의하기
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}