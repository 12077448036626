import { useEffect, useRef, useState } from 'react';
import './../../../App.css';
import { MultiPassPage } from '../../../components/Payment/MultiPassPage';
import { baseInstance, authInstance } from '../../../modules/api/api';
import Header from '../../Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckoutPage } from '../../../components/TossPayment/Checkout';

import { Buffer } from 'buffer';

export default function Payment(props){
    const userData = props.userData;
    const navigate = useNavigate();

    const clickPrice = (number, price, name, id) => {
        navigate('/payment/next', {state: {
            itemPrice : price,
            itemNumber : number,
            itemName: name
        }})
    }

    // // Test!!!!
    // const widgetSecretKey = "test_gsk_docs_OaPz8L5KdmQXkzRz3y47BMw6";
    // const encryptedSecretKey = "Basic " + Buffer.from(widgetSecretKey + ":").toString("base64");
    // console.log(encryptedSecretKey)
    // localStorage.setItem('number_of_churr', 1);

    // // try for free
    // let couponType = null
    // const tryForFree = async () => {
    //     couponType = 'daily';
    //     if (userData) {
    //         navigate('/serverAuth2', {state : {
    //             couponType : couponType
    //             }
    //           })
    //     } else {
    //         alert('무료 체험을 위해서 우선 로그인을 해주세요.')
    //         navigate('/user/signin')
    //     }
    // }

    // // use coupon
    const [code, setCode] = useState(null);
    // const [isClicked, setIsClicked] = useState(false);
    // const askToUseCoupon = () => {
    //     if (userData) {
    //         setIsClicked(!isClicked);
    //     } else {
    //         alert('쿠폰을 사용하기 위해서 우선 로그인을 해주세요.')
    //         navigate('/user/signin')
    //     }
    // }
    const checkCode = () => {
        if (code == 'churrchurp1!' || code == 'churrchance1' || code == 'churrthx_1') {
            navigate('/payment/coupon', {state : {
                code : code
                }
            })
        } else {
            alert('잘못된 쿠폰 코드입니다. 다시 확인해주세요.')
        }
    }


    return(
        <>
            <Header userData={userData} />
            <div className='payment'>
                <div className='payment__con'>
                    <ul className='payment__churr-wrap'>
                        <li>나의 츄르르</li>
                        <img src='/images/churr.png' />
                        <li>X&nbsp;&nbsp;<span>{userData?.freeTicket.isValid == true ? '12시간 무제한 이용권 1' : userData?.churr}개</span></li>
                    </ul>
                    <div className='payment__logo-wrap'>
                        <img src='/images/cat_payment.png' />
                        <span>
                            구매한 타로 해석은 회원 유지기간동안 <br className='br-mo'/>언제든지 확인할 수 있습니다.
                        </span>
                        <span>
                            <span>‼주의 :</span> 결제 진행 중 또는 결제 완료 후에 <br className='br-mo'/><span>‘새로고침’ 및 ‘뒤로가기’ 버튼을 누르지 마세요.</span> <br/>본인의 실수로 인한 경우 <span>환불되지 않습니다‼</span>
                        </span>
                    </div>
                    <ul className='payment__btn-con'>
                        <li>츄르르 충전하기</li>
                        {/* <li>야옹이가 알려주는 타로 해석 보기</li> */}
                        {/* <li><span>1,900</span>원</li> */}
                        {/* <MultiPassPage userData={userData} /> */}
                        {/* <li onClick={()=>{askToUseCoupon()}}>쿠폰 사용하기</li> */}
                        {/* <li onClick={()=>{tryForFree()}}>1회&nbsp;&nbsp;무료뽑기</li> */}
                    </ul>
                    <div className='payment__select-con'>
                    <ul className='payment__select-row-con'>
                            {[[920806, 0, 11900, '12시간 무제한 이용권']].map((a,i)=>{
                                return(
                                    <li
                                    onClick={()=>{clickPrice((a[0] +a[1]), a[2], a[3], (i+1))}}
                                    >
                                        <img src='/images/churr.png' />
                                        <div>
                                            <span>{`12시간 무제한 이용권`}</span>{a[1] > 0 ? '+' : ''}<span>{` ${a[1] ? a[1]+'개' : ''}`}</span>
                                        </div>
                                        <div className='payment__price'>
                                            {/* <li>
                                                <span>
                                                    {a[2].toLocaleString()}원
                                                </span>
                                            </li> */}
                                            <span>
                                                {a[2].toLocaleString()}원
                                            </span>
                                        </div>
                                        {/* <span>
                                            {a[2].toLocaleString()}원
                                        </span> */}
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className='payment__select-row-con'>
                            {[[1, 0, 1000, '', ''],[2, 0, 1990, 2000, '']].map((a,i)=>{
                                return(
                                    
                                    <li
                                    onClick={()=>{clickPrice((a[0] +a[1]), a[2], (i+1))}}
                                    >
                                        <img src='/images/churr.png' />
                                        <div>
                                            <span>{`츄르르 ${a[0]}개`}</span>{a[1] > 0 ? '+' : ''}<span>{`${a[1] ? a[1]+'개' : ''}`}</span>
                                        </div>
                                        <div className='payment__price'>
                                            <ul className='payment__price_sale'>
                                                <li>
                                                    <span>
                                                        {a[3] == '' ? '' : a[3].toLocaleString()+'원'}
                                                    </span>
                                                    <span>
                                                        {a[4] == '' ? '' : a[4].toLocaleString()+'%'}
                                                    </span>
                                                </li>
                                            </ul>
                                            <span>
                                                {a[2].toLocaleString()}원
                                            </span>
                                        </div>
                                        {/* <span>
                                            {a[2].toLocaleString()}원
                                        </span> */}
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className='payment__select-row-con'>
                            {[[10, 0, 9900, 10000, ''], [30, 2, 29500, 32000, 8]].map((a,i)=>{
                                return(
                                    <li
                                    onClick={()=>{clickPrice((a[0] +a[1]), a[2], '츄르르', (i+4))}}>
                                        <img src='/images/churr.png' />
                                        <div>
                                            <span>{`츄르르 ${a[0]}개`}</span>{a[1] > 0 ? '+' : ''}<span>{`${a[1] ? a[1]+'개' : ''}`}</span>
                                        </div>
                                        <div className='payment__price'>
                                            <ul className='payment__price_sale'>
                                                <li>
                                                    <span>
                                                        {a[3] == '' ? '' : a[3].toLocaleString()+'원'}
                                                    </span>
                                                    <span>
                                                        {a[4] == '' ? '' : a[4].toLocaleString()+'%'}
                                                    </span>
                                                </li>
                                            </ul>
                                            {/* <span>
                                                {a[2].toLocaleString()}원
                                            </span> */}
                                            <span>
                                                {a[2].toLocaleString()}원
                                            </span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className='payment__select-row-con'>
                            {[[50, 4, 49000, 54000, 9],[100, 10, 98000, 110000, 11]].map((a,i)=>{
                                return(
                                    <li
                                    onClick={()=>{clickPrice((a[0] +a[1]), a[2], '츄르르', (i+4))}}>
                                        <img src='/images/churr.png' />
                                        <div>
                                            <span>{`츄르르 ${a[0]}개`}</span>{a[1] > 0 ? '+' : ''}<span>{`${a[1] ? a[1]+'개' : ''}`}</span>
                                        </div>
                                        {/* <span>
                                            {a[2].toLocaleString()}원
                                        </span> */}
                                        <div className='payment__price'>
                                            <ul className='payment__price_sale'>
                                                <li>
                                                    <span>
                                                        {a[3] == '' ? '' : a[3].toLocaleString()+'원'}
                                                    </span>
                                                    <span>
                                                        {a[4] == '' ? '' : a[4].toLocaleString()+'%'}
                                                    </span>
                                                </li>
                                            </ul>
                                            <span>
                                                {a[2].toLocaleString()}원
                                            </span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className='payment__event-con'>
                        <div className='payment__event-title'>쿠폰 사용하기</div>
                        <div className='payment__event-inner-flex'>
                            <label className='payment__event-submit-wrap'>
                                <input
                                    type= 'text'
                                    onChange={(e) => {setCode(e.target.value)}}
                                    placeholder='쿠폰코드를 입력하세요'
                                    className='chat-content__input'
                                    value={code}
                                />
                            </label>
                            <span 
                            className='payment__event-btn'
                            onClick={()=>{checkCode()}}>등록</span>
                        </div>    
                    </div>
                </div>

                {/* <div className='payment__modal' style={{display: isClicked ? 'flex' : 'none'}}>
                    <div className='payment__btn-x-wrap'>
                        <img src='/icons/btn_x.png' onClick={()=>{askToUseCoupon()}} />
                    </div>
                    <div>쿠폰코드를 입력하세요</div>
                    <label className='payment__coupon'>
                        <input
                            type= 'text'
                            onChange={(e) => {setCode(e.target.value)}}
                            placeholder='쿠폰코드'
                            className='chat-content__input'
                            value={code}
                            />
                    </label>
                    <button
                    className='payment__coupon-submit'
                    onClick={()=>{checkCode()}}>사용하기</button>
                </div> */}
            </div>
        </>
    )
}