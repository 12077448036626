


const currentDate = (el) => { // el is type of new Date
    // date
    const year = el?.getFullYear();
    const month = ('0' + (el?.getMonth() + 1)).slice(-2);
    const day = ('0' + el?.getDate()).slice(-2);
    const dateString = year + '-' + month  + '-' + day;

    // time
    const hours = ('0' + el?.getHours()).slice(-2); 
    const minutes = ('0' + el?.getMinutes()).slice(-2);
    const seconds = ('0' + el?.getSeconds()).slice(-2); 
    const timeString = hours + ':' + minutes  + ':' + seconds;

    return (dateString);
}

const currentTime = (el) => {
    // time
    const hours = ('0' + el?.getHours()).slice(-2); 
    const minutes = ('0' + el?.getMinutes()).slice(-2);
    const seconds = ('0' + el?.getSeconds()).slice(-2); 
    const timeString = hours + ':' + minutes  + ':' + seconds;

    return (timeString);
}


export const dateInstance = currentDate;
export const timeInstance = currentTime;