import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../App.css';


export default function Header(props){
    const userData = props.userData;

    const navigate = useNavigate();

    const clickLogin = () => {
        if (!userData) {
            navigate('/user/signin');
            window.location.reload();
        } else {
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('room_id');
            navigate('/chat/category');
            window.location.reload();
        }
    }

    // 햄버거 버튼 클릭시 메뉴 보이기
    const [clickHamburger, setClickHamburger] = useState(null);
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };


    return (
        <>
            <header className='header'>
                <div className='header__con'>
                    {/* PC */}
                    <ul className='header__logo-con'>
                        <li className='header__logo'>
                            <a href='/'>
                                <img src={'/images/logo.png'} decoding="async"/>
                            </a>
                        </li>
                        
                        <ul className='header__category-con'>
                            <li>
                                <a href='/chat/category'>
                                    채팅
                                </a>
                            </li>
                            <li>
                                <a href='/user/info'>
                                    유저정보
                                </a>
                            </li>
                        </ul>
                    </ul>

                    <ul className='header__category-con'>
                        <li>
                            <a onClick={()=>{clickLogin()}} style={{cursor: 'pointer'}}>
                                {userData ? '로그아웃' : '로그인'}
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Mobile */}
                <div className='header-mo__con'>
                    <li className='header-mo__arrow'>
                        <a onClick={() => {navigate(-1)}}>
                        {/* <a href='/chat/category'> */}
                            <img src='/icons/arrow.png' />
                        </a>
                    </li>

                    <li>
                        <a href='/'>
                            Tell Meow
                        </a>
                    </li>

                    <li className='header-mo__hamburger'>
                        <a>
                            <img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/>
                        </a>
                    </li>
                </div>
            </header>

            <div className={`header-sb ${clickHamburger ? 'left-0' : '' }`}>
                <ul className='header-sb__category-con'>
                    <li>
                        <a href='/chat/category'>
                            채팅
                        </a>
                    </li>
                    <li>
                        <a href='/user/info'>
                            유저정보
                        </a>
                    </li>
                    <div className='header__contact-btn-wrap'>
                        <li>
                            <a onClick={()=>{clickLogin()}}>
                                {userData ? '로그아웃' : '로그인'}
                            </a>
                        </li>
                    </div>
                </ul>
            </div>

            <div className='bg-gray' style={{display: clickHamburger? 'block' : 'none'}} onClick={showList}></div>

            <div className='header-block'></div>
        </>
    )
}
