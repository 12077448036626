import axios from "axios";
import React, { useEffect, useState } from "react";
import { adminInstance } from "../../modules/api/api";

const NaverLogin = (props) => {

    const forProd = process.env.NODE_ENV !== 'development';
    const [secretKey, setSecretKey] = useState(null);
    const getAPI = async () => {
        try {
            const resp = await adminInstance.get('user')
            setSecretKey(resp.data)
            // console.log(resp)
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        getAPI();
    }, [])

    const REDIRECT_URI = forProd ? "https://tellmeow.xyz/user/auth/callback/naver" : "http://localhost:3000/user/auth/callback/naver";
    // const REDIRECT_URI = "http://localhost:3000/user/auth/callback/naver";
    // const REDIRECT_URI = "https://tellmeow.xyz/user/auth/callback/naver";
    const STATE = "1"; // 문자열이기만 하면 상관없음
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${secretKey?.REACT_APP_NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

    const NaverLogin = () => {
        window.location.href = NAVER_AUTH_URL;
    };

    return (
        <li className="naver_login" onClick={NaverLogin}>
            <img className="naver_icon" src= '/icons/naver_icon.png' decoding="async"/>
            <div className="naver_title">네이버로 로그인 / 회원가입</div>
        </li>
    )
}

export default NaverLogin;


