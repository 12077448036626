import { useEffect, useState } from 'react';
import './../App.css';
import { useLocation } from 'react-router-dom';
import Footer from './Footer'

export default function Intro(){
    const { pathname } = useLocation();
    localStorage.setItem('pathname', pathname)
    console.log('path >>>>', pathname)
    localStorage.removeItem('tid');

    return(
        <>
            <div className='intro'>
                <div className='intro__con'>
                    <div className='intro__logo-wrap'>
                        <img src='/images/logo_head.png' />
                        <span>TELL MEOW</span>
                    </div>
                    <div className='intro__img-con'>
                        {/* <div>
                            <span>고민을 말해봐!</span>
                        </div> */}
                        {/* <img src='/images/cats.png' /> */}
                        <img src='/images/logogif.gif' />
                    </div>
                    <ul className='intro__btn-con'>
                        <li>
                            <a href='/chat/category'>시작하기</a>
                        </li>                        
                        {/* <ul>
                            <li><a href='/user/signin'>로그인</a></li>
                            <li><a href='/user/signin'>회원가입</a></li>
                        </ul> */}
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}