import { useEffect, useRef, useState } from 'react'
import './../../../App.css'
import Header from '../../Header'
import inputData from '../../../modules/inputData/inputData'
import { useNavigate, useLocation } from 'react-router-dom'
import { baseInstance } from '../../../modules/api/api'

export default function CategoryNew(props) {
  const now = new Date()
  localStorage.removeItem('room_id')

  // for mobile
  const displayMo = matchMedia('screen and (max-width: 1023px)') // js 미디어쿼리

  // user data
  const userData = props.userData
  let [dataObj, setDataObj] = useState({})
  useEffect(() => {
    inputData(setDataObj, 'name', userData?.name)
  }, [userData])

  // room id
  const random = (length = 8) => {
    return Math.random()
               .toString(16)
               .substr(2, length)
  }

  const { pathname } = useLocation()
  localStorage.setItem('pathname', pathname)
  localStorage.removeItem('tid')
  if (!userData) localStorage.setItem('user_id_not_login', random())

  // retrieve categories
  const [category, setCategory] = useState(null)
  const getCategory = async () => {
    try {
      const resp = await baseInstance.get(`subject`)
      // console.log(resp.data)
      setCategory(resp.data)
    } catch (err) {
      console.log('API Error >>', err)
    }
  }
  useEffect(() => {
    getCategory()
  }, [])

  // Check
  const navigate = useNavigate()
  const [checkItems, setCheckItems] = useState(null)
  const selectChecked = (i) => {
    setCheckItems(i)
    localStorage.setItem('category', i)
    inputData(setDataObj, 'category', 'checked')
    navigate(userData ? '/chat/room' : '/user/signin')
  }

  // // Change name
  // const changeName = (a) => {
  //     localStorage.setItem('nickname', a);
  //     inputData(setDataObj, 'name', a);
  // }

  // Scroll down
  const scrollRef = useRef()
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }

  // need to use it as custom hook
  const divRef = useRef()
  const handleVisualViewPortResize = () => {
    const currentVisualViewport = Number(window.visualViewport?.height)
    if (divRef) {
      divRef.current.style.height = `${currentVisualViewport - 0}px`
      setTimeout(() => {
        window.scrollTo(0, 0)
        scrollToBottom() // import this hook
      }, 100)
    }
    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewPortResize
    }
  }
  useEffect(() => {
    if (displayMo.matches) handleVisualViewPortResize()
  }, [])

  return (
    <>
      <Header userData={userData} />
      <div className="chat flex">
        <div className="chat-sb__con">
          <li className="chat-sb__new">+ 새 채팅</li>

          <ul className="chat-sb__list-con">
            {/* <li>타로1</li> */}
            {/* <li>타로1</li> */}
          </ul>
        </div>

        <div
          className="category category-new"
          ref={divRef}
        >
          <div
            className="category__con"
            ref={scrollRef}
          >
            <div className="category-new__logo-wrap">
              <img src="/images/logo_head.png" />
              <span>TELL MEOW</span>
            </div>

            <ul className="category__btn-con">
              {
                // [
                //     '그 사람한테\n연락이 올까?',
                //     '그 사람이 나를\n좋아할 확률은?',
                //     '그 사람과의 연애는\n성공할 수 있을까?',
                //     '그 사람의 속마음',
                //     '그 사람한테\n연락해도 될까?',
                //     '그 사람은 왜\n연락을 안 할까?'
                // ]
                category?.map((a, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        (checkItems == i ? 'category__btn-clicked' : '') +
                        (a.hot ? 'category__btn-hot' : '') +
                        (a.best ? 'category__btn-best' : '')
                      }
                      onClick={() => {selectChecked(i)}}
                    >
                      <span>{a.title.split('\n')
                              .map((line) => (<div>{line}</div>))}</span>
                      <img src={a.cat} />


                      <div
                        className={
                          (a.hot ? 'category__font-hot' : '') +
                          (a.best ? 'category__font-best' : '')
                        }
                      >&#10029; {(a.hot ? 'HOT' : '') +
                        (a.best ? 'BEST' : '')} &#10029;</div>
                      {/* <img src={`/images/cat_category0${i+1}.png`} /> */}
                    </li>

                  )
                })}
            </ul>

            {/* <ul className='category__input-con'>
                            <li>
                                <label>
                                    <input
                                        type='text'
                                        onChange={(e)=>{changeName(e.target.value)}}
                                        placeholder='닉네임'
                                        value={dataObj.name}
                                    />
                                </label>
                            </li>
                            <li className='category__start category__start-before' style={{display: dataObj.category && dataObj.name ? 'none' : 'block'}}>
                                <a>시작하기</a>
                            </li>
                            <li className='category__start' style={{display: dataObj.category && dataObj.name ? 'block' : 'none'}}>
                                <a href={userData ? '/chat/room' : '/user/signin'}>시작하기</a>
                            </li>
                        </ul> */}
          </div>
        </div>
      </div>
    </>
  )
}
