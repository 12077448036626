import axios from 'axios'
// import { getItem } from '@utils/storage'
import { Buffer } from 'buffer'

const baseURL = 'https://prod.tellmeow.xyz'

const baseAPI = axios.create({
    baseURL: baseURL,
    headers:{
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

const token = localStorage.getItem('user_token')
const authAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json', 
        Authorization: token ? `bearer ${token}` : "bearer xhdekfrcjstk",
        // Authorization: token ? `bearer ${token}` : "xhdekfrcjstk",
    }
})

// const widgetSecretKey = "test_sk_ex6BJGQOVDEk6mMZBz1OrW4w2zNb";
// const encryptedSecretKey = "Basic " + Buffer.from(widgetSecretKey + ":").toString("base64");
// const tossAPI = axios.create({
//     baseURL: baseURL,
//     headers: {
//         'Content-Type': 'application/json', 
//         Authorization: encryptedSecretKey,
//     }
// })

const paidToken = localStorage.getItem('paid_token')
const authCardAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${paidToken}`,
    }
})

const refreshToken = localStorage.getItem('refresh_token')
const reAuthAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${refreshToken}`,
    }
})

const adminKey = 'clagkgkwnrehfdl2@ '
const adminAuthAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${adminKey}`,
    }
})

const fileAuthAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${token}`,
    }
})


export const baseInstance = baseAPI;
export const authInstance = authAPI;
// export const tossInstance = tossAPI;
export const authCardInstance = authCardAPI;
export const reAuthInstance = reAuthAPI;
export const adminInstance = adminAuthAPI;
export const fileAuthInstance = fileAuthAPI;