import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';


export default function CallbackNaver(){
    const navigate = useNavigate();

    // url에 있는 code, state 추출
    const tokenCode = new URL(window.location.href).searchParams.get('code');
    const tokenState = new URL(window.location.href).searchParams.get('state');
    const provider = 'naver';
    const [accessToken, setAccessToken] = useState(null);
    useEffect(() => {
        const axiosGet = () => {
            axios
            .get(`https://prod.tellmeow.xyz/social/auth/callback?code=${tokenCode}&state=${tokenState}&provider=${provider}`)
            .then(response => {
                console.log('success1', response);
                localStorage.setItem('access_token_naver', response.data.response.access_token);
                localStorage.setItem('refresh_token_naver', response.data.response.refresh_token);
                setAccessToken(response.data.response.access_token);
            })
            .catch(err => {
                console.log('Error 1 >>', err);
            })
        }
        axiosGet();
    }, [])


    // [개발중] 유저 데이터 추출
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const axiosGet = () => {
            axios
            .get(`https://prod.tellmeow.xyz/social/me?provider=${provider}`,
            {
                headers: {Authorization: `Bearer ${accessToken}`},
            })
            .then(response => {
                console.log('success2', response);
                setUserData(response.data.user.response)
            })
            .catch(err => {
                console.log('Error 2 >>', err);
            })
        }
        axiosGet();
    }, [accessToken])

    // [API] 서버로 회원가입
    const [userId, setUserId] = useState(null);
    useEffect(() => {
        if (userData) {
            const axiosPost = () => {
                axios
                .post(`https://prod.tellmeow.xyz/user/signin`,{
                    name: userData?.name,
                    // phone_no: userData?.mobile,
                    email: userData?.email,
                    birth: userData?.birthyear,
                    gender: userData?.gender
                },
                {
                    headers: {Authorization: `Bearer ${accessToken}`,},
                })
                .then(response => { // 동일한 email 없으면 회원가입. 이미 존재하면 로그인됨.
                    // console.log('success>>>>>>', response.data.access_token);
                    localStorage.setItem('user_token', response.data.access_token);
                    localStorage.setItem('user_id', response.data.user_id);
                    setUserId(response.data.user_id);
                    alert('로그인이 완료되었습니다.')

                    if (localStorage.getItem('pathname') == '/chat/room') {
                        navigate("/payment", { replace: true });
                        window.location.reload()
                    } else {
                        navigate("/chat/category", { replace: true });
                        window.location.reload();
                    }
                    
                })
                .catch(err => {
                    console.log('Error 3 >>', err);
                })
            }
            axiosPost();
        } else {
            console.log('userData not yet')
        }
    }, [userData])


    return (
        <>
            <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
                로그인 중입니다.
            </div>
        </>
    )
}