import { useEffect, useRef, useState } from 'react'
import './../../../App.css'
import Header from '../../Header'
import { dateInstance, timeInstance } from '../../../modules/currentTime/currentTime'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
// import currentTime from '../../../modules/currentTime/currentTime';

export default function UserInfo(props) {

  const userData = props.userData
  const paidData = userData?.paid
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // for mobile
  const displayMo = matchMedia('screen and (max-width: 1023px)') // js 미디어쿼리

  const clickToCharge = () => {
    localStorage.setItem('pathname', pathname)
    navigate('/payment')
  }

  // Change Object
  let [dataObj, setDataObj] = useState({})

  // Pagination
  const onepage = 6
  // const onepage = displayMo.matches ? 6 : 10;
  const totalPage = Math.ceil((paidData?.length) / onepage)
  const pageArr = []
  for (let i = 0; i < totalPage; i++) {
    pageArr.push(i + 1)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const clickPageNumber = (e) => {
    setCurrentPage(e)
  }
  const [arrowNum, setArrowNum] = useState(0)
  const batchOfPages = 5
  const clickNextArrow = (e) => {
    console.log(totalPage, 't')
    console.log(batchOfPages, 'b')
    if (arrowNum < (totalPage - batchOfPages)) {
      setArrowNum(arrowNum + batchOfPages)
    }
  }
  const clickPrevArrow = (e) => {
    if (!arrowNum == 0) {setArrowNum(arrowNum - batchOfPages)}
  }

  return (
    <>
      <Header userData={userData} />
      <div className="userinfo flex">
        <div className="userinfo__left-con">
          <ul className="userinfo__left-img-con">
            <li className="userinfo__left-img">
              <img src="/images/user_cat.png" />
            </li>
            <li className="userinfo__left-img-txt">{userData?.name}</li>
          </ul>

          <div className="userinfo__churr-flex-con">
            {/* pc */}
            <ul className="userinfo__churr-wrap">
              <img src="/images/churr.png" />
              <li>X
                <span>{userData?.freeTicket.isValid == true ? '12시간 무제한 이용권 1' : userData?.churr}개</span>
              </li>
            </ul>
            {/* mo */}
            <div className="userinfo__churr-wrap-mo">
              <ul>
                <img src="/images/churr.png" />
                <li>
                  <span>나의 츄르르</span>
                </li>
              </ul>
              <li>{userData?.freeTicket.isValid == true ? '12시간 무제한 이용권 1' : userData?.churr}개</li>
            </div>


            <ul className="userinfo__charge-con">
              <li onClick={() => {clickToCharge()}}>
                <span>츄르르 충전</span>
                <img src="/images/small_cat.png" />
              </li>
              <li onClick={() => {clickToCharge()}}>
                쿠폰 등록
              </li>
            </ul>
          </div>


        </div>
        <div className="userinfo__right-con">
          <div className="userinfo__right-header">결제내역</div>

          <table className="userinfo__table">
            <thead className="userinfo__thead">
              <tr>
                <th className="userinfo__th01">결제일시</th>
                <th className="userinfo__th02">결제내용</th>
                <th className="userinfo__th03">결제액</th>
                <th className="userinfo__th04">결제수단</th>
                <th className="userinfo__th05">결제상태</th>
              </tr>
            </thead>
            <tbody className="userinfo__tbody">
              {paidData?.slice((currentPage - 1) * onepage, (currentPage - 1) * onepage + onepage)
                       .map((a, i) => {
                         return (
                           <tr key={i}>
                             <td>
                               {dateInstance(new Date(a.approvedAt))}
                               <br className="br-mo" />
                               {timeInstance(new Date(a.approvedAt))}
                             </td>
                             <td>{a.orderName}</td>
                             <td>{a.balanceAmount}원</td>
                             <td>{a.method}</td>
                             <td>결제완료</td>
                           </tr>
                         )
                       })}
            </tbody>
          </table>

          <div className="userinfo-table-number">
            <div
              className="userinfo-table-arrow"
              onClick={() => {clickPrevArrow()}}
            >&#8249;</div>
            {pageArr?.slice(arrowNum, (arrowNum + batchOfPages))
                    .map((a, i) => {
                      return (
                        <div
                          key={i}
                          style={{ fontWeight: a == currentPage ? '600' : '300' }}
                          onClick={() => {clickPageNumber(a)}}
                        >
                          {a}
                        </div>
                      )
                    })}
            <div
              className="userinfo-table-arrow"
              onClick={() => {clickNextArrow()}}
            >&#8250;</div>
          </div>

        </div>
      </div>
    </>
  )
}
