import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import './../../../App.css'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../Header'
import Payment from '../Payment/Payment'
import { Swiper, SwiperSlide } from 'swiper/react'
import { authInstance } from '../../../modules/api/api'
import { Autoplay } from 'swiper/modules'

export default function Room(props) {
  const now = new Date()
  const [isEnd, setIsEnd] = useState(false)

  // user data & check a room
  const userData = props.userData
  // console.log(userData);
  const arrangeData = async () => {
    let filteredData = null
    const filtering = userData.rooms?.filter(function(value) {
      // console.log('value >>>>>', value)
      // console.log('q id >>>>>', localStorage.getItem('category'))
      return (parseInt(value.q_type) == parseInt(localStorage.getItem('category')))
    })
    filteredData = filtering?.reverse()[0]?.id

    // console.log('room id >>>>>', userData.rooms)
    // console.log('q id >>>>>', parseInt(localStorage.getItem('category')))

    if (location.state?.roomId) {
      localStorage.setItem('room_id', location.state.roomId) // after payment
    } else {
      localStorage.setItem('room_id', filteredData) // before payment
    }

    getOrCreateRoom()
  }
  useEffect(() => {
    // for solving error
    // if (userData) console.log('check0', location);
    // if (userData) console.log('check1', userData);
    // if (userData) console.log('check2', userData.rooms);
    // if (userData) console.log('check3', userData.rooms?.filter(function(value){
    //     return (parseInt(value.q_type) == parseInt(localStorage.getItem("category")))
    // }));

    if (userData) arrangeData()
  }, [userData])
  const getOrCreateRoom = async () => {
    try {
      // console.log('room id >>>>>', localStorage.getItem('room_id'))
      const resp = await authInstance.get(`chat/v3/room/${localStorage.getItem('room_id')}?q_type=${localStorage.getItem('category')}`, {
        room_id: localStorage.getItem('room_id'),
      })
      if (resp.data.chat) setChatData(resp.data.chat[0].data)
      console.log('get room completely!!!')
    } catch (err) {
      if (err.response && err.response.status == 401 && err.response.data.detail == 'Token expired.') {
        // console.log('token expire>>>>')
        navigate(userData ? '/chat/room' : '/user/signin')
      }

      const resp = await authInstance.post(`chat/v3/room/${localStorage.getItem('category')}`, {
        q_type: localStorage.getItem('category'),
      })
      localStorage.setItem('room_id', resp.data.room_id)
      console.log('room created completely!!')
    }
  }

  // const arrangeData = () => {
  //     let filteredData = null
  //     const filtering = userData?.rooms?.filter(function(value){
  //         return (parseInt(value.q_type) == localStorage.getItem("category"))
  //     })

  //     filteredData = filtering?.reverse()[0]?.id
  //     localStorage.setItem("room_id", filteredData)

  //     const test = getRoom();
  //     console.log(test);

  //     if (!filteredData && userData) {
  //         createRoom()
  //     } else if (filteredData) {
  //         getRoom()
  //     }
  // }
  // useEffect(() => {
  //     arrangeData();
  // }, [userData])

  // // get room
  // const getRoom = async () => {
  //     try {
  //         const resp = await authInstance.get(`chat/v3/room/${localStorage.getItem("room_id")}?q_type=${localStorage.getItem("category")}`, {
  //             room_id: localStorage.getItem('room_id')
  //         })
  //         if (resp.data.chat) setChatData(resp.data.chat[0].data)
  //         console.log("get room completely")
  //     } catch (err) {
  //         console.log('API Error >>', err)
  //     }
  //   }

  // // create room
  // const [isNewRoom, setIsNewRoom] = useState(false);
  // const createRoom = async () => {
  //     try {
  //         const resp = await authInstance.post(`chat/v3/room/${localStorage.getItem("category")}`, {
  //             q_type: localStorage.getItem("category"),
  //         })
  //         localStorage.setItem('room_id', resp.data.room_id);
  //         setIsNewRoom(true);
  //         console.log("room created completely")
  //     } catch (err) {
  //         console.log('API Error >>', err)
  //     }
  // }

  // for mobile
  const displayMo = matchMedia('screen and (max-width: 1023px)') // js 미디어쿼리

  // store pathname
  const navigate = useNavigate()
  const { pathname } = useLocation()
  localStorage.setItem('pathname', pathname)
  const [payment, setPayment] = useState(false)

  // Initialization Settings
  const [step, setStep] = useState(0)
  let [talkArr, setTalkArr] = useState([])

  // after getting data from payment page
  const location = useLocation()
  let deliveredData = location.state?.data
  const [chatData, setChatData] = useState([]) // 과거에 저장된 채팅데이터
  useEffect(() => {
    let arr = []

    chatData.length ? setTalkArr(chatData) : setTalkArr([
      {
        content: `만나서 반가워!<br>나에게 고민을 말해주면 타로를 봐줄게`,
        isUser: 0,
        time: now,
      },
    ])

    if (deliveredData && chatData.length) {
      showCardText()
    } else if (!deliveredData && chatData.length) {
      arr = [...chatData, { content: `다시 만나서 반가워.<br>이번에는 어떤 고민 때문에 왔어?`, isUser: 0, time: now }]
      setTalkArr(arr)

      setTimeout(() => {
        scrollToBottom()
      }, 500)
    }
  }, [chatData])

  // Choose Random Index
  let cardIndex = null
  // const [cardIndex, setCardIndex] = useState(null);
  const selectIndex = (min, max) => {
    const reverse = Math.floor(Math.random() * (2 - 1 + 1) + 1)
    const randomNum = Math.floor(Math.random() * (max - min + 1) + min)
    if (randomNum < 10) {
      cardIndex = '1_0' + randomNum
    } else {
      cardIndex = reverse + '_' + randomNum
    }

    localStorage.setItem('cardIndex', cardIndex)
  }

  // [Step 0] AI Chatbot
  const [loading, setLoading] = useState(null)
  const [dataInput, setDataInput] = useState('')
  const lastResp = talkArr.filter((el) => {return el.isUser == 0})
                          .pop() // The lastest reponse data
  // Post API
  const postAPI = async () => {
    let arr = []
    try {
      if (!(now - lastResp.time < 100 || dataInput == '')) { // Prevent too many requests in a short time
        setLoading(true)

        console.log('room_id >>>>>', localStorage.getItem('room_id'))
        const resp = await authInstance.post(`gpt/v3/chat/${localStorage.getItem('room_id')}`, {
          room_id: localStorage.getItem('room_id'),
          isUser: 1,
          content: String(dataInput),
          time: now,
        })

        const botTalk = String(resp.data.message)
          .replace(/{username}/gi, localStorage.getItem('nickname'))
        // console.log(botTalk);

        arr = [...talkArr, { content: botTalk, isUser: 0, time: now }]
        setTalkArr(arr)

        setLoading(false)

        if (step == 0) {
          setTimeout(() => {
            arr.push({
              content: '타로의 대답도 한 번 들어볼래?<br/>(더 구제척으로 이야기하고 싶으면 대화창에 말을해줘! 혹시 바로 타로를 보고싶으면 카드뽑기를 눌러줘!)',
              isUser: 0,
              time: now,
            })
            setTalkArr(arr)
            setRefresh(refresh => refresh * -1) // For reload
          }, 500)

          setTimeout(() => {
            setStep(step + 1) // show cards set
          }, 1000)

          setTimeout(() => {
            scrollToBottom()
          }, 1200)
        }
      }
    } catch (err) {
      console.log('API Error >>', err)
      setLoading(false)
    }
  }

  // Click Send-button
  const clickSend = () => {
    talkArr.push({ content: dataInput, isUser: 1, time: now })
    setRefresh(refresh => refresh * -1) // For reload
    postAPI()
  }

  // Reload without window.location.reload()
  const [refresh, setRefresh] = useState(1)
  useEffect(() => {
    talkArr = [...talkArr]
    setDataInput('')
    scrollToBottom()
    // setTalkArr(location.state.data)
  }, [refresh, talkArr])
  // Scroll down
  const scrollRef = useRef()
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }

  // ask to select card
  const askCard = () => {
    let arr = []

    if (step == 1) {
      setTimeout(() => {
        arr = [...talkArr, { content: '타로 카드를 뽑고싶어', isUser: 1, time: now }]
        setTalkArr(arr)
        setRefresh(refresh => refresh * -1)
      }, 500)
      setTimeout(() => {
        arr.push({ content: '좌우로 스크롤 하면 카드를 고를 수 있어!<br/>느낌이 오는 카드를 한 장 뽑아봐', isUser: 0, time: now })
        setTalkArr(arr)
        setRefresh(refresh => refresh * -1)

        // selectIndex(0,21);
        setStep(step + 1)
      }, 1000)
    }
  }

  // [Step 1] Select Card
  const selectCard = async () => {
    selectIndex(0, 21)
    let arr = [...talkArr]
    arr = [...talkArr, { content: '선택 완료', isUser: 1, time: now }]
    setTalkArr(arr)

    setTimeout(() => {
      arr.push({
        content: `<img src='https://tellmeow.s3.ap-northeast-2.amazonaws.com/taro-images/tarocard/${cardIndex}.jpg' />`,
        isUser: 0,
        time: now,
      })
      setTalkArr(arr)
      setRefresh(refresh => refresh * -1)
    }, 500)
    setTimeout(() => {
      setStep(3)
      scrollToBottom()
    }, 1000)
  }

  // ask to use churr
  const [isClicked, setIsClicked] = useState(false)
  // use Churr
  const clickToUseChurr = async () => {
    if (userData?.churr > 1 || userData?.freeTicket.isValid == true) {
      navigate('/payment/usingchurr')
    } else {
      alert('츄르르 충전이 필요합니다. 해설을 보기 위해서는 최소 2개의 츄르르가 필요합니다.')
      navigate('/payment')
    }
  }

  let churr = userData?.churr
  const clickDescBtn = async () => {
    try {
      const resp = await authInstance.patch(`gpt/v3/chat/${localStorage.getItem('room_id')}`, {
        q_type: localStorage.getItem('category'),
        chat: [
          { data: talkArr },
          { cardIndex: localStorage.getItem('cardIndex') },
          { roomId: localStorage.getItem('room_id') },
        ],
      })
      setIsClicked(!isClicked)
    } catch (err) {
      console.log('API Error >>', err)
    }
  }

  // [Step 1] Select Card
  const showCardText = async () => {
    let arr = [...chatData]
    try {
      const resp = await authInstance.post(`chat/result?q_type=${localStorage.getItem('category') ? localStorage.getItem('category') : ''}&card_idx=${location.state?.cardIndex || localStorage.getItem('cardIndex')}`)

      setTimeout(() => {
        arr.push({
          content: {
            name: resp.data.name,
            keyword: resp.data.keyword,
            probability: resp.data.probability,
          }, isUser: 0, time: now,
        })
        setTalkArr(arr)
        setRefresh(refresh => refresh * -1)
      }, 500)

      setTimeout(() => {
        const botTalk = String(resp.data.result)
          .replace(/{username}/gi, localStorage.getItem('nickname'))
        arr.push({ content: botTalk, isUser: 0, time: now })
        setTalkArr(arr)
        setRefresh(refresh => refresh * -1)

        setIsEnd(true) // terminate chat
        setStep(4)

        const resp2 = authInstance.patch(`gpt/v3/chat/${localStorage.getItem('room_id')}`, {
          q_type: localStorage.getItem('category'),
          chat: [
            { data: arr },
            { cardIndex: localStorage.getItem('cardIndex') },
            { roomId: localStorage.getItem('room_id') },
          ],
        })
      }, 1000)
    } catch (err) {
      console.log('API Error >>', err)
      console.log('error!!!')
    }
  }

  // enter key event
  const activeEnter = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return
    } else if (!displayMo.matches && e.key === 'Enter') {
      clickSend()
      setTimeout(() => {
        setDataInput('')
      }, 10)
    }
  }

  // need to use it as custom hook
  const divRef = useRef()
  const handleVisualViewPortResize = () => {
    const currentVisualViewport = Number(window.visualViewport?.height)
    if (divRef) {
      divRef.current.style.height = `${currentVisualViewport}px`
      setTimeout(() => {
        window.scrollTo(0, 0)
        scrollToBottom() // import this hook
      }, 100)
    }
    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewPortResize
      window.visualViewport.onscroll = handleVisualViewPortResize
    }
  }
  useEffect(() => {
    if (displayMo.matches) handleVisualViewPortResize()
  }, [])

  const newChat = () => {
    chatData = ''
    navigate('/chat/room')
    window.location.reload()
  }

  const moreChat = () => {
    setStep(0)
    setIsEnd(false)
  }

  return (
    <>
      <meta
        name="theme-color"
        content="#F9F9F9"
      />
      <Header userData={userData} />
      {payment ? <Payment
        talkArr={talkArr}
        cardIndex={cardIndex}
      /> : ''}
      <div
        className="chat flex"
        ref={divRef}
      >
        <div className="chat-sb__con">
          <li className="chat-sb__new">
            <a
              href="/chat/room"
              onClick={() => {newChat()}}
            >+ 새 채팅
            </a>
          </li>

          <ul className="chat-sb__list-con">
            {/* <li>타로1</li> */}
            {/* <li>타로1</li> */}
          </ul>
        </div>

        <div className="chat-content">
          <div className="chat-content__con">
            <ul
              className="chat-content__talk-con"
              ref={scrollRef}
            >
              {talkArr?.map((a, i) => {
                return (
                  <li
                    key={i}
                    className="chat-content__talk-item"
                    style={{ flexDirection: a.isUser == 1 ? 'row-reverse' : '' }}
                  >
                    {/* icon */}
                    <span style={{ display: a.isUser == 1 ? 'none' : 'flex' }}><img
                      src="/images/chat_cat.png"
                      decoding="async"
                    /></span>

                    <div>
                      {/* name */}
                      <span style={{ display: a.isUser == 1 ? 'none' : 'block' }}>Tell Meow</span>

                      <div className={`chat-content__talk-item-box ${a.isUser == 1 ? 'chat-user-pre' : 'chat-bot-pre'}`}>

                        {/* circles */}
                        <ul
                          className="chat-content__circles-con"
                          style={{ display: a.content.name ? 'block' : 'none' }}
                        >
                          <li>
                            <div>카드</div>
                            <div>{a.content.name}</div>
                          </li>
                          <li>
                            <div>키워드</div>
                            <div>{a.content.keyword}</div>
                          </li>
                          <li>
                            <div>확률</div>
                            <div>{a.content.probability}</div>
                          </li>
                        </ul>

                        {/* content */}
                        <pre
                          dangerouslySetInnerHTML={{ __html: a.content }}
                          style={{ display: a.content.name ? 'none' : 'block' }}
                        ></pre>
                      </div>

                      {/* ask to select card */}
                      <div
                        className="chat-content__ask-con"
                        onClick={() => {askCard()}}
                        style={{ display: step == 1 && a.isUser == 0 ? '' : 'none' }}
                      >
                        카드 뽑기
                      </div>
                      {/* ask to select card */}
                      <div
                        className="chat-content__ask-con"
                        onClick={() => {clickDescBtn()}}
                        style={{ display: step == 3 && a.isUser == 0 ? '' : 'none' }}
                      >
                        해설 보기
                      </div>

                      {/* cards */}
                      <ul
                        className="chat-content__cards-con"
                        style={{
                          display: step == 2 && a.isUser == 0 && talkArr.length > 3 ? '' : 'none',
                        }}
                      >
                        <Swiper
                          modules={[Autoplay]}
                          spaceBetween={0}
                          slidesPerView={4.7}
                          // loop={true}
                          className="swiper-wrapper"
                        >
                          {[
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                            '13',
                            '14',
                            '15',
                            '16',
                            '17',
                            '18',
                            '19',
                            '20',
                            '21',
                            '22',
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                            '13',
                            '14',
                            '15',
                            '16',
                            '17',
                            '18',
                            '19',
                            '20',
                            '21',
                            '22',
                          ].map((a, i) => {
                            return (
                              <SwiperSlide key={i}>
                                <img
                                  src={`/images/card_back.jpeg`}
                                  decoding="async"
                                  onClick={() => {selectCard()}}
                                />
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      </ul>

                    </div>
                  </li>
                )
              })}
            </ul>
            <div className="chat-content__loading">{loading ? 'TellMeow 님이 입력중입니다...' : ''}</div>

            <ul
              className="chat-content__btn-more-con"
              style={{ display: step == 4 ? 'flex' : 'none' }}
            >
              <li onClick={() => {moreChat()}}>더 질문하기</li>
              <li>
                <a href="/chat/category">새로 질문하기</a>
              </li>
            </ul>

            <div
              className="chat-content__input-wrap"
              style={{ display: isEnd ? 'none' : '' }}
            >
                            <textarea
                              type="text"
                              onChange={(e) => {setDataInput(e.target.value)}}
                              placeholder="메세지를 입력해주세요."
                              className="chat-content__input"
                              value={dataInput}
                              onKeyDown={(e) => activeEnter(e)}
                            />
              <span onClick={() => {clickSend()}}><img
                src="/icons/send.svg"
                decoding="async"
              /></span>
            </div>
          </div>
        </div>

        <div
          className="payment__modal"
          style={{ display: isClicked ? 'flex' : 'none' }}
        >
          <div
            className="payment__btn-x-wrap"
            onClick={() => {setIsClicked(!isClicked)}}
          >
            <span>취소</span>
            <img src="/icons/btn_x.png" />
          </div>
          <ul>
            <li>
              <a>
                <span>해설 보기</span>
                <div>
                  <img src="/images/churr.png" />
                  <li>X 2</li>
                </div>
              </a>
            </li>
            <li>
              <a onClick={() => {clickToUseChurr()}}>
                <img src="/images/logo_head.png" />
                츄르르로 해설 보기
              </a>
            </li>
            <span>사용 가능 츄르르 : {userData?.freeTicket.isValid == true ? '12시간 무제한 이용권 1' : userData?.churr}개</span>
          </ul>
        </div>
      </div>
    </>
  )
}
