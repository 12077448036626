import { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authInstance, tossInstance } from '../../../modules/api/api';

export default function TossCallback(props){

    const forProd = process.env.NODE_ENV !== 'development';
    const navigate = useNavigate();
    const userData = props.userData;

    // extract values in url
    const paymentKey = new URL(window.location.href).searchParams.get('paymentKey');
    const orderId = new URL(window.location.href).searchParams.get('orderId');
    const amount = new URL(window.location.href).searchParams.get('amount');
    const numberOfChurr = localStorage.getItem('number_of_churr');
    const pathname = localStorage.getItem('pathname');


    const postAPI = async () => {
        try {
            const resp1 = await authInstance.post(`payment/member/${forProd ? 'confirm' : 'free'}?paymentKey=${paymentKey}&orderId=${orderId}&amount=${amount}&number_of_churr=${numberOfChurr}`)


            if (pathname === '/chat/room') {
                // const usingChurr = await authInstance.patch(`user/churr?amount=${2}`)
                // console.log(usingChurr);

                const resp2 = await authInstance.get(`chat/v3/room/${localStorage.getItem("room_id")}?q_type=${localStorage.getItem("category")}`, {
                    room_id : localStorage.getItem('room_id')
                })

                const usingChurr = await authInstance.patch(`churr?q_type=${localStorage.getItem("category")}&amount=${2}`)
                console.log('churr >>>>', usingChurr)
                
                alert('결제가 완료되었습니다. 타로결과 페이지로 이동합니다.');
                navigate(localStorage.getItem('pathname'), {state : {
                    data : resp2.data.chat[0].data,
                    cardIndex : resp2.data.chat[1].cardIndex,
                    roomId : localStorage.getItem('room_id')
                }});
                window.location.reload();
            }

            else if (pathname === '/user/info') {
                alert('충전이 완료되었습니다. 유저정보 페이지로 이동합니다.');
                navigate(localStorage.getItem('pathname'));
                window.location.reload();
            }

            else {
                alert('일시적인 오류가 발생했습니다. 문의주시면 빠르게 해결해드리겠습니다.');
                navigate(localStorage.getItem('pathname'));
                window.location.reload();
            }
        } catch (err) {
            if (err.response?.status === 403) {
                alert('해설을 보기 위해서는 2개의 츄르르가 필요합니다.')
                navigate('/payment')
                window.location.reload();
            }
            console.log('API Error >>', err)
        }
    }
    useEffect(() => {
        postAPI();
    }, [])



  return (
      <>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
              잠시만 기다려주세요.<br/>
              결제 중입니다.
          </div>
      </>
    )
}
